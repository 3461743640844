<template>
  <div class="breadcrumbs-wrapper">
    <p><router-link to="">Project Tracking</router-link> /</p>
  </div>

  <div class="tracking-wrapper">
    <div class="project-search">
      <div
        v-if="allProjects && allProjects.length && project && project.id"
        class="filters_select"
      >
        <select @change="showProjectTrakingDetails($event)">
          <option value="" disabled>Select project</option>
          <option
            v-for="item in allProjects"
            :key="`ALL_${item.id}`"
            :value="item.id"
            :selected="project.id == item.id"
          >
            ( #{{ item.feasibility_number }} ) {{ item.project_number }}
          </option>
        </select>
      </div>

      <div v-if="project" class="tracking-main-data">
        <div class="tracking-main-data-item">
          <IconEducation icon-color="#17A2B8" />
          <span class="tracking-main-data-item-text"
            >#{{ project.feasibility_number }} /
            {{ project.project_number }}</span
          >
        </div>

        <div class="tracking-main-data-item">
          <IconCalendar2 icon-color="#17A2B8" />
          <span class="tracking-main-data-item-text">{{
            project.created_at.slice(0, 10)
          }}</span>
        </div>
      </div>
    </div>

    <div class="tracking">
      <TrackingColumnInreview title="In review" :cards="inReview" />
      <TrackingColumnOrdered title="Accepted proposals" :cards="ordered" />
      <TrackingColumnSent title="In transit" :cards="shipped" />
      <TrackingColumnReceived title="Received" :cards="received" />
      <TrackingColumnQC title="QC Complete" :cards="passedQC" />
    </div>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import TrackingColumnInreview from "@/components/TrackingColumnInreview.vue";
import TrackingColumnOrdered from "@/components/TrackingColumnOrdered.vue";
import TrackingColumnSent from "@/components/TrackingColumnSent.vue";
import TrackingColumnReceived from "@/components/TrackingColumnReceived.vue";
import TrackingColumnQC from "@/components/TrackingColumnQC.vue";
import IconEducation from "@/modules/components/icons/IconEducation";
import IconCalendar2 from "@/modules/components/icons/IconCalendar2";

export default {
  components: {
    TrackingColumnInreview,
    TrackingColumnOrdered,
    TrackingColumnSent,
    TrackingColumnReceived,
    TrackingColumnQC,
    IconEducation,
    IconCalendar2,
  },
  setup() {
    const store = useStore();
    store.dispatch("projects/getTrackingProject");
    const trackingProject = computed(
      () => store.getters["projects/trackingProject"]
    );

    const allProjects = computed(
      () => trackingProject.value?.allProjects || null
    );
    const project = computed(() => trackingProject.value?.project || null);
    const inReview = computed(() => trackingProject.value?.onReview);
    const ordered = computed(() => trackingProject.value?.accepted);
    const shipped = computed(() => trackingProject.value?.shipped);
    const received = computed(() => trackingProject.value?.received);
    const passedQC = computed(() => trackingProject.value?.passedQC);
    const searchTerm = ref("");

    const searchProjectNumbers = () => {
      const params = { page: 1, s: searchTerm.value || "0" };

      store.dispatch("projects/findTrackedProjectsId", params);
    };

    const showProjectTrakingDetails = (e) => {
      const id = e.target.value;

      store.dispatch("projects/getTrackingProject", id);
    };

    return {
      trackingProject,
      allProjects,
      project,
      inReview,
      ordered,
      shipped,
      received,
      passedQC,
      searchTerm,
      searchProjectNumbers,
      showProjectTrakingDetails,
    };
  },
};
</script>

<style lang="scss" scoped>
.project-search {
  padding: 20px;
  margin: 5px 5px 15px;
  border-radius: 8px;
  background-color: #fff;

  input {
    width: 300px;
  }
}
.filters_select select {
  width: 400px;
}
.tracking {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  overflow: auto;
  height: 100%;

  &-main-data {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding: 15px 15px 0;

    &-item {
      padding-right: 10px;
      &-text {
        padding-left: 10px;
      }
    }
  }
}
</style>

<style lang="scss">
.column {
  min-width: 290px;
  overflow-y: auto;
  flex-basis: 100%;
  height: 100%;
  border: 1px solid var(--col-secondary-btn);
  padding: 5px;
  border-radius: 14px;
  background-color: var(--col-bg-primary);

  &-wrapper {
    margin: 5px;
  }

  &-bg {
    height: 100%;
    padding: 5px;
    border: none;
    border-radius: 14px;
    background-color: var(--col-bg-secondary);
  }

  &-title {
    font-size: 18px;
    line-height: 24px;
    font-family: "OpenSans-Semibold";
    text-align: center;
    color: var(--col-info);
  }

  &-card {
    margin: 10px 0;
    border: 1px solid var(--col-secondary-btn);
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 1px 2px 10px #00000014, -1px 2px 10px #00000014;

    &-head {
      position: relative;
      padding: 20px 12px 8px;
    }

    &-code {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 14px;
      line-height: 1.9;
      font-weight: 700;
      font-family: "SFProDisplay-Medium";
      border-radius: 4px;

      &-prefix {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: auto;
        min-width: 120px;

        padding: 2px 8px;
        color: var(--col-contrast-text);
        background-color: var(--col-info);
        span {
          padding-left: 4px;
        }
      }
    }

    &-date {
      font-size: 10px;
      color: var(--col-primary-dark);
      font-family: "SFProDisplay-Thin";
    }

    &-body {
      padding: 8px 12px 15px;
      color: #000;

      &-item {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;

        h3,
        h4,
        p {
          &:first-of-type {
            flex-basis: 40%;
          }
        }
        &.short {
          h3,
          h4,
          p {
            &:first-of-type {
              flex-basis: 10%;
            }
          }
        }
      }

      &-title {
        font-size: 14px;
        font-weight: 700;
        line-height: 1.9;
        color: var(--col-primary-dark);
        font-family: "SFProDisplay-Medium";
      }

      &-quantity {
        font-size: 14px;
        line-height: 1.9;
        color: var(--col-attention);
        font-family: "SFProDisplay-Medium";
      }

      &-lineid {
        font-size: 12px;
        line-height: 1.9;
        color: var(--col-secondary);
      }

      &-transfercode {
        font-size: 12px;
        line-height: 1.9;
        font-style: italic;
        color: var(--col-primary-dark);
      }

      &-site {
        font-size: 14px;
        line-height: 1.9;
        font-weight: 700;
        color: var(--col-info);
        font-family: "SFProDisplay-Medium";
      }
    }
  }
}
</style>
