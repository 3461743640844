<template>
  <div class="column-wrapper">
    <div class="column">
      <div class="column-bg">
        <h4 class="column-title green">{{ title }}</h4>
        <div
          class="column-card"
          v-for="card in cards"
          :key="card.p_id"
          :style="`border-right: 9px solid ${card.pl_color};`"
        >
          <div class="column-card-head">
            <div v-if="card.plp_price">
              <h4 class="">Received:</h4>
              <h2>
                <span class="green">{{ card.ps_cases_count }}</span>
              </h2>
            </div>
            <hr style="margin-top: 0" />
          </div>

          <div class="column-card-body">
            <div class="column-card-body-item">
              <h4 class="grey">Indication:</h4>
              <h4 class="green">{{ card.pl_title }}</h4>
            </div>

            <div class="column-card-body-item short">
              <h4 class="green"></h4>
              <h4 class="grey">
                <i>{{ card.ps_tracking }}</i>
              </h4>
            </div>

            <div
              v-if="card.ps_date_created_at"
              class="column-card-body-item short"
            >
              <h4 class="green"></h4>
              <h4 class="grey">
                <span>{{ card.ps_date_created_at.slice(0, 10) }}</span>
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    cards: {
      type: Object,
    },
    title: {
      type: String,
      default: "",
    },
  },
  setup() {},
};
</script>
