<template>
  <div class="column-wrapper">
    <div class="column">
      <div class="column-bg">
        <h4 class="column-title green">{{ title }}</h4>
        <div
          class="column-card"
          v-for="card in cards"
          :key="card.p_id"
          :style="`border-right: 9px solid ${card.pl_color};`"
        >
          <div class="column-card-head">
            <div v-if="card.plp_price">
              <h4 class="">Price:</h4>
              <h2 v-if="user.role !== 'admin'" class="green">
                $ {{ card.plp_price }}
              </h2>
              <h4 v-else class="red">is available only for admin</h4>
            </div>
            <hr style="margin-top: 0" />
          </div>

          <div class="column-card-body">
            <div class="column-card-body-item">
              <h4 class="grey">Indication:</h4>
              <h4 class="green">{{ card.pl_title }}</h4>
            </div>

            <div class="column-card-body-item">
              <h4 class="grey">Wanted:</h4>
              <h4 class="green">{{ card.pl_cases_count }}</h4>
            </div>

            <!-- <div class="column-card-body-item">
              <h4 class="grey">Available:</h4>
              <h4 class="green">{{ card.plp_available }}</h4>
            </div> -->

            <div v-if="card.plp_to_collect" class="column-card-body-item">
              <h4 class="grey">To collect:</h4>
              <h4>
                <span class="green">{{ card.plp_to_collect }}</span>
                <span class="grey">{{ card.plp_order_date }}</span>
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
export default {
  props: {
    cards: {
      type: Object,
    },
    title: {
      type: String,
      default: "",
    },
  },
  setup() {
    const store = useStore();
    let user = computed(() => store.getters["auth/user"]);

    return { user };
  },
};
</script>
